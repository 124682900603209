export const DEFAULT_META_CONTENTS = {
	title: 'n3233',
	type: 'website',
	image: '/assets/images/website-og-cover-photo.webp',
	siteName: 'FindQo.ie',
};

export const HOME_META_CONTENTS = {
	title: 'Find Properties to Buy, Sell, Rent, or Share in Ireland - FindQo.ie',
	description:
		'Find houses, apartments, land, offices, and more from trusted agents and landlords. Free for all users.FindQo.ie: Buy, sell, or rent properties in Ireland.',
};

export const HOME_RENT_META_CONTENTS = {
	title: 'Rental Properties in Ireland | Apartments, Houses & Offices for Rent - FindQo.ie',
	description:
		'Find apartments, houses, and office spaces for rent all over Ireland from trusted house owners and agents. Find your ideal home or workspace today!',
};

export const HOME_SHARE_META_CONTENTS = {
	title: 'Properties to Share in Ireland | Rooms and Shared Accommodations - FindQo.ie',
	description:
		'Find properties to share in Ireland with FindQo.ie. Get rooms for rent and shared accommodations tailored to students, professionals for affordable housing.',
};

export const HOME_BUY_META_CONTENTS = {
	title: 'Properties for Sale in Ireland | Houses, Apartments & Commercial - FindQo.ie',
	description:
		'Find properties for sale across Ireland on FindQo.ie. Find houses, apartments, and commercial spaces in your desired location, all from verified agents and sellers.',
};

export const ABOUT_META_CONTENTS = {
	description: `FindQo.ie engineering team, a team of 31 highly skilled Software Engineers, designers, and Solution Architects with the mission to address Ireland's unique property rental challenges mixed with the latest tech.`,
};

export const AGENT_SIGNUP_META_CONTENTS = {
	title: 'Register Free to Advertise your property - FindQo.ie Sign up',
	description: `Sign up to advertise your Real Estate in Ireland for Free. Post houses, apartments, shops, offices and more absolutely free.`,
};

export const AGENT_LANDING_META_CONTENTS = {
	title: 'Advertise Properties in Ireland - Agents Start Free - FindQo.ie',
	description: `Post your real estate ads effortlessly in Ireland! Reach more buyers and sellers with targeted property listings. Start now and boost your listings.`,
};

export const SELLER_META_CONTENTS = {
	description: (seller: string, address: string) => `${seller} properties in ${address}`,
};

export const CONTACT_US_SEO = {
	title: 'Contact Real Estate Agent in Ireland - FindQo.ie',
	description: `Contact us for top real estate services in Ireland! Connect with agents, explore listings, and get expert advice. Your dream home awaits!`,
};

export const PLACE_AD_GUIDE_META_CONTENTS = {
	title: 'Advertise your property - List Irish Real Estate Ads Free',
	description: 'Advertise your real estate in Ireland for Free. Post houses, apartments, shops, offices, bungalows and more absolutely free.',
};

export const FEEDBACK_META_CONTENTS = {
	title: 'FindQo.ie Feedback Form',
	description: 'Submit your feedback about FindQo.ie. Your feedback matters. Submit your suggestions, and issues.',
};

export const PROPERTY_WORTH_META_CONTENTS = {
	title: 'Free Irish House Valuation Tool - Property Valuation Calculator',
	description: 'Enter property info and get your house value instantly based on latest Irish house prices and real estate data. Get price for any property.',
};
