export enum AmplitudeEvent {
	// Send Enquiry Funnel
	AD_LIST_SEND_ENQUIRY_CLICKED = 'Ad List Send Enquiry Clicked',
	AD_DETAILS_SEND_ENQUIRY_CLICKED = 'Ad Details Send Enquiry Clicked',
	NO_ENQUIRY_POPUP_SEND_ENQUIRY_CLICKED = 'No Enquiry Popup Send Enquiry Clicked',
	IMAGE_CAROUSEL_SEND_ENQUIRY_CLICKED = 'Image Carousel Send Enquiry Clicked',
	ENQUIRY_FORM_OPENED = 'Enquiry Form Opened',
	ENQUIRY_FORM_SUBMITTED = 'Enquiry Form Submitted',
	SUBMIT_ENQUIRY_FORM_SUCCESS = 'Submit Enquiry Form Success',
	SUBMIT_ENQUIRY_FORM_FAILED = 'Submit Enquiry Form Failed',

	// Login Funnel
	LOGIN_FORM_OPENED = 'Login Form Opened',
	LOGIN_FORM_CONTINUE_CLICKED = 'Login Form Continue Clicked',
	OTP_FORM_OPENED = 'OTP Form Opened',
	OTP_FORM_VERIFY_CLICKED = 'OTP Form Verify Clicked',
	WELCOME_FORM_OPENED = 'Welcome Form Opened',
	WELCOME_FORM_CONTINUE_CLICKED = 'Welcome Form Continue Clicked',
	INTEREST_SURVEY_FORM_OPENED = 'Interest Survey Form Opened',
	INTEREST_SURVEY_FORM_CONTINUE_CLICKED = 'Interest Survey Form Continue Clicked',
}
