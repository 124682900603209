{
	"name": "@findqo/source",
	"version": "10.3.56",
	"license": "MIT",
	"scripts": {
		"serve:ssr:ie": "node dist/apps/findqo-ireland/server/server.mjs",
		"serve:staging:dashboard": "nx run dashboard:serve",
		"serve:prod:dashboard": "nx run dashboard:serve --configuration production",
		"build:ssr:staging:ie": "nx run findqo-ireland:build",
		"build:ssr:prod:ie": "nx run findqo-ireland:build --configuration production",
		"build:sitemap:ie": "python apps/findqo-ireland/scripts/create_listed.py && python apps/findqo-ireland/scripts/create_properties.py",
		"build:staging:dashboard": "nx run dashboard:build",
		"build:prod:dashboard": "nx run dashboard:build --configuration production"
	},
	"private": true,
	"dependencies": {
		"@angular/animations": "~17.0.0",
		"@angular/cdk": "^17.0.4",
		"@angular/common": "~17.0.0",
		"@angular/compiler": "~17.0.0",
		"@angular/core": "~17.0.0",
		"@angular/forms": "~17.0.0",
		"@angular/material": "^17.0.4",
		"@angular/platform-browser": "~17.0.0",
		"@angular/platform-browser-dynamic": "~17.0.0",
		"@angular/platform-server": "~17.0.0",
		"@angular/router": "~17.0.0",
		"@angular/ssr": "^17.0.8",
		"@ng-bootstrap/ng-bootstrap": "^14.2.0",
		"@nx/angular": "17.2.8",
		"@popperjs/core": "^2.11.6",
		"@sentry/angular": "^8.42.0",
		"@sentry/cli": "^2.39.1",
		"bootstrap": "^5.2.3",
		"browser-image-compression": "^2.0.2",
		"canvas-confetti": "^1.9.3",
		"dayjs": "^1.11.10",
		"email-validator": "^2.0.4",
		"express": "~4.18.2",
		"heic2any": "^0.0.3",
		"json-2-csv": "^5.5.7",
		"marked": "^15.0.3",
		"ng2-adsense": "^13.0.0",
		"ngx-device-detector": "^5.0.1",
		"ngx-skeleton-loader": "^7.0.0",
		"rxjs": "~7.8.0",
		"swiper": "^9.3.2",
		"tslib": "^2.3.0",
		"youtube-player": "^5.6.0",
		"zone.js": "~0.14.0"
	},
	"devDependencies": {
		"@angular-devkit/build-angular": "~17.0.0",
		"@angular-devkit/core": "~17.0.0",
		"@angular-devkit/schematics": "~17.0.0",
		"@angular-eslint/eslint-plugin": "~17.0.0",
		"@angular-eslint/eslint-plugin-template": "~17.0.0",
		"@angular-eslint/template-parser": "~17.0.0",
		"@angular/cli": "~17.0.0",
		"@angular/compiler-cli": "~17.0.0",
		"@angular/language-service": "~17.0.0",
		"@nx/cypress": "17.2.8",
		"@nx/eslint": "17.2.8",
		"@nx/eslint-plugin": "17.2.8",
		"@nx/jest": "17.2.8",
		"@nx/js": "17.2.8",
		"@nx/web": "17.2.8",
		"@nx/workspace": "17.2.8",
		"@schematics/angular": "~17.0.0",
		"@swc-node/register": "~1.6.7",
		"@swc/core": "~1.3.85",
		"@types/crypto-js": "^4.1.1",
		"@types/express": "4.17.14",
		"@types/gtag.js": "^0.0.13",
		"@types/jest": "^29.4.0",
		"@types/node": "^20.10.6",
		"@types/youtube-player": "^5.5.9",
		"@typescript-eslint/eslint-plugin": "^6.9.1",
		"@typescript-eslint/parser": "^6.9.1",
		"cypress": "^13.0.0",
		"eslint": "~8.48.0",
		"eslint-config-prettier": "^9.0.0",
		"eslint-plugin-cypress": "^2.13.4",
		"jest": "^29.4.1",
		"jest-environment-jsdom": "^29.4.1",
		"jest-preset-angular": "~13.1.4",
		"jsonc-eslint-parser": "^2.1.0",
		"nx": "17.2.8",
		"prettier": "^3.2.5",
		"source-map-explorer": "^2.5.3",
		"ts-jest": "^29.1.0",
		"ts-node": "10.9.1",
		"typescript": "~5.2.2"
	}
}
