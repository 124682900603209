export enum StorageType {
	USER = 'user',
	SHOW_DIALOG_ERROR = 'show_error_dialog',
	LOCALE = 'locale',
	TRANSLATIONS = 'translations',
	SHOW_LANG_SELECTION_DIALOAG = 'show_lang_selection_dialog',
	TRANSLATIONS_UPDATED_AT = 'translations_updated_at',
	COOKIE_CONSENT = 'cookie_consent',
	IGNORE_SURVEY = 'ignoreSurvey',
	SURVEY_COUNT = 'survey_count',
	SURVEY_SHOWN_AT = 'survey_shown_at',
	ENQUIRY_SEND_ATTEMPT_COUNT = 'enquiry_send_attempt_count',
	ENQUIRY_FORM_VALUES = 'enquiry_form_values',
}
