export enum TimeSlotFieldKeys {
	DAY = 'dayId',
	START_TIME = 'startTimeId',
	END_TIME = 'endTimeId',
	TIME_ZONE = 'timezoneId',
	AUTO_STRETCH_START_TIME = 'autoStretchStartTime',
	AUTO_STRETCH_END_TIME = 'autoStretchEndTime',
}

export enum MeetingType {
	PROPERTY_VIEWING = '1',
	PERSONAL = '2',
}

export enum DaysIndex {
	SUNDAY = 0,
	MONDAY = 1,
	TUESDAY = 2,
	WEDNESDAY = 3,
	THURSDAY = 4,
	FRIDAY = 5,
	SATURDAY = 6,
}
